
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

.logo-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.logo-uploader .el-upload:hover {
	border-color: $blue;
}
