
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

.copy-links p {
	padding: 12px 15px;
	border-radius: 3px;
	border: 1px solid #cacaca;
	background-color: #f3f3f3;
	color: #464646;
	font-size: 11px;
}
